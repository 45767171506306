import React from "react";
import Login from "./components/Login";
import Signup from "./components/Signup";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/Home";
import './index.css'
import ActionFigures from "./components/ActionFigures";
import Funko from "./components/Funko";
import ProductPage from "./components/ProductPage";
import EditProduct from "./components/EditProduct";
import SearchResults from './components/SearchResult'
import { CartProvider } from "./components/CartContext";
import Cart from "./components/Cart";
import Profile from "./components/Profile";
import AdminPage from "./components/AdminPage";
import ExternalSearch from './components/ExternalSearch'
import ContactUs from "./components/ContactUs";

function App() {

  return (
    <CartProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/signup' element={<Signup />}></Route>
          <Route path='/products/funko-pops' element={<Funko />}></Route>
          <Route path='/products/action-figures' element={<ActionFigures />}></Route>
          <Route path='/products/:id' element={<ProductPage />}></Route>
          <Route path="/admin/products/edit/:id" element={<EditProduct />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/external-search" element={<ExternalSearch />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </CartProvider>
  )
}

export default App;
