import React, {createContext, useContext, useEffect, useState} from 'react';
import {json} from "react-router-dom";

// Create a context for the cart
const CartContext = createContext()

// Custom hook to use the CartContext
export const useCart = () => useContext(CartContext)

// Provider component to wrap your application and provide the cart state
export const CartProvider = ({ children }) => {
  const userEmail = localStorage.getItem('email');
  const [cart, setCart] = useState(() => {
    if(userEmail) {
      const savedCart = userEmail ? localStorage.getItem(`cart_${userEmail}`) : null
      return savedCart ? JSON.parse(savedCart) : []
    }
    return [];
  })

  useEffect(() => {
    if(userEmail) {
      localStorage.setItem(`cart_${userEmail}`, JSON.stringify(cart));
    }
  }, [cart]);

  const addToCart = (product) => {
    setCart((prevCart) => [...prevCart, product])
  }

  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter(product => product.id !== productId));
  };

  const clearCart = () => {
    setCart([]);
  };

  return(
    <CartContext.Provider value={{ cart, setCart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  )
}
