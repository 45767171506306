import React, {useEffect} from 'react';
import {useCart} from "./CartContext";
import {Link} from "react-router-dom";
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";

export default function Cart() {
  const { cart, setCart, removeFromCart } = useCart()
  const userEmail = localStorage.getItem('email');
  const dollarPrice = Number(process.env.REACT_APP_DOLLAR_PRICE);

  useEffect(() => {
   if (userEmail) {
     const savedCart = localStorage.getItem(`cart_${userEmail}`)
     if (savedCart) {
       setCart(JSON.parse(savedCart));
     }
   }
  }, [userEmail, setCart]);

  useEffect(() => {
    if (userEmail) {
      localStorage.setItem(`cart_${userEmail}`, JSON.stringify(cart));
    }
  }, [cart, userEmail]);

  const cartPrice = cart.map((item) => parseInt(item.price))
  const totalAmount = cartPrice.reduce((a, b) => a + b, 0)

  const formatPrice = (price) => {
    return price.toLocaleString();
  };

  return (
    <div className="text-center overflow-hidden">
      <Navbar/>
      {/*<BackButton />*/}
      <div className="container mx-auto p-4 mb-20">
        <h1 className="text-2xl font-bold mb-4">Shopping Cart</h1>
        {cart.length === 0 ? (
          <p>Your cart is empty. <Link to="/">Go shopping!</Link></p>
        ) : (
          <div className="space-y-4">
            {cart.map((product, index) => (
              <div key={index} className="flex items-center space-x-4 border-b py-2">
                <Link to={`/products/${product.id}`} className="flex items-center space-x-4 flex-1">
                  <img
                    src={`${process.env.REACT_APP_FRONT_URL}${product.images[0]}`}
                    alt={product.name} className="w-16 h-16 object-cover" />
                  <div>
                    <h2 className="text-lg font-semibold">{product.name}</h2>
                    <p className="text-left text-gray-600">{formatPrice(product.price * dollarPrice)}</p>
                  </div>
                </Link>
                <button
                  onClick={() => removeFromCart(product.id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Remove
                </button>
              </div>
            ))}
            <div className="text-left text-lg font-bold">Total: {formatPrice(totalAmount.toFixed(2) * dollarPrice)} </div>

          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

