import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from "./shared/Navbar";

export default function AdminPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    genre: ''
  });

  const [images, setImages] = useState([]);
  const [genres, setGenres] = useState(['Superheroes', 'Music', 'Sport', 'Gaming', 'Anime-Manga', 'Movies-TV']);

  useEffect(() => {
    const adminToken = localStorage.getItem('adminToken');
    if (adminToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_FRONT_URL}/api/admin/login`, credentials);
      const token = response.data.token;
      localStorage.setItem('adminToken', token);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Invalid Credentials');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsLoggedIn(false);
  };

  const handleInputChange = (e) => {
    setProduct({...product, [e.target.name]: e.target.value})
  }

  const handleFileChange = (e) => {
    setImages(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('adminToken');

    // Create FormData to send product data along with images
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('category', product.category);
    formData.append('genre', product.genre);

    // Append each image file to the formData
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_FRONT_URL}/api/admin/products`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(response.data);
      alert('Product added successfully');
      setProduct({
        name: '',
        description: '',
        price: '',
        category: '',
        genre: ''
      });
      setImages([]);
    } catch (err) {
      console.error(err);
      alert('Failed to add product');
    }
  };

  return (
    <div className="overflow-hidden">
      <Navbar />
      <div className="min-h-full pb-32 justify-center align-center bg-slate-400 pt-16">
        {isLoggedIn ? (
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Admin Panel</h2>
            <div className="max-w-md mx-auto bg-white shadow-md rounded p-8 mt-8">
              <h2 className="text-xl font-semibold mb-4">Add New Product</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Name</label>
                  <input type="text" name="name" value={product.name} onChange={handleInputChange} className="block w-full border rounded px-4 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Description</label>
                  <textarea name="description" value={product.description} onChange={handleInputChange} className="block w-full border rounded px-4 py-2"></textarea>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Images</label>
                  <input type="file" name="images" multiple onChange={handleFileChange} className="block w-full border rounded px-4 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Price</label>
                  <input type="number" name="price" value={product.price} onChange={handleInputChange} className="block w-full border rounded px-4 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Category</label>
                  <input type="text" name="category" value={product.category} onChange={handleInputChange} className="block w-full border rounded px-4 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">Genre</label>
                  <select name="genre" value={product.genre} onChange={handleInputChange} className="block w-full border rounded px-4 py-2">
                    <option value="">Select Genre</option>
                    {genres.map((genre) => (
                      <option key={genre} value={genre}>
                        {genre}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Add Product</button>
              </form>
            </div>
            <div className="text-center">
              <button
                onClick={handleLogout}
                className="mt-16 bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md transition duration-300"
              >
                Logout
              </button>
            </div>
          </div>
        ) : (
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Login to Admin Panel</h2>
            <div className="w-full justify-center align-center mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleLogin}>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="username">
                    <strong>Username</strong>
                  </label>
                  <input
                    type="text"
                    name="username"
                    value={credentials.username}
                    onChange={handleChange}
                    placeholder="Enter Your Username"
                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="password">
                    <strong>Password</strong>
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={credentials.password}
                    onChange={handleChange}
                    placeholder="**********"
                    id="password"
                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <button className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" type="submit">
                  Log in
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
