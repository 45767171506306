import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

export default function Pagination({ visibleProducts, productsLength, currentPage, onPageChange, showAll, showMoreLoading}) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(productsLength / 8); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="col-span-full">
      {visibleProducts < productsLength && (
        <div className="w-full mt-5 col-start-1 col-span-2 lg:col-start-2">
          {showMoreLoading ? (
            <ClipLoader color="#4A90E2" loading={showMoreLoading} size={50} />
          ) : (
            <button
              onClick={showAll}
              className="text-lg text-blue-400 py-2 px-4 rounded hover:text-blue-700"
            >
              Show All
            </button>
          )}
        </div>

      )}
      {visibleProducts < productsLength &&
        <div className="w-full text-white flex items-end justify-center mt-5 col-start-1 col-span-full">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-2 py-1 mx-1"
          >
            &lt;
          </button>
          {pageNumbers.map(number => (
            <button
              key={number}
              onClick={() => onPageChange(number)}
              className={`px-2 py-1 mx-1 ${currentPage === number ? 'text-blue-500' : ''}`}
            >
              {number}
            </button>
          ))}
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(productsLength / 8)}
            className="px-2 py-1 mx-1"
          >
            &gt;
          </button>
        </div>
      }
    </div>
  );
}
