import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import axios from "axios";
import Navbar from "./shared/Navbar";
import {useCart} from "./CartContext";
import Footer from "./shared/Footer";

export default function Profile() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false)
  const { clearCart } = useCart();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token')
      try {
        const response = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        setUser(response.data.email)

        const passwordResponse = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/password`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Current password:', passwordResponse.data);

        const userName = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/user/name`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        console.log('Current User:', userName.data);

      } catch (error) {
        setError('Error fetching user: ' + (error.response ? error.response.data.message : error.message));
        console.error('Error fetching user:', error)
      }
    }
    fetchUser()
  }, [])

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      const userEmail = localStorage.getItem('email');
      await axios.post(`${process.env.REACT_APP_FRONT_URL}/api/logout`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      clearCart();
      setUser(null);
      setLoggedOut(true)
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPass) {
      setError('New password and confirm new password do not match');
      return;
    }
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FRONT_URL}/api/user/password`,
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMessage(response.data.message);
      setError(null);
    } catch (error) {
      setError('Error changing password: ' + (error.response ? error.response.data.message : error.message));
      console.error('Error changing password:', error);
    }
  };

  return (
    <div className="text-center">
      <Navbar />
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
        <div className="max-w-md w-full bg-white shadow-md p-8 rounded-md">
          {user ? (
            <div>
              <h1 className="text-2xl font-semibold mb-4">User Profile</h1>
              <p className="text-gray-700">Email: {user}</p>
              <form onSubmit={handleChangePassword} className="mt-4">
                <div>
                  <label className="block text-gray-700">Current Password</label>
                  <input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="mt-2 p-2 border rounded-md w-full"
                    required
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">New Password</label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="mt-2 p-2 border rounded-md w-full"
                    required
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">Confirm New Password</label>
                  <input
                    type="password"
                    value={confirmNewPass}
                    onChange={(e) => setConfirmNewPass(e.target.value)}
                    className="mt-2 p-2 border rounded-md w-full"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md transition duration-300"
                >
                  Change Password
                </button>
              </form>
              {successMessage && <p className="text-green-500 mt-2">{successMessage}</p>}
              {error && <p className="text-red-500 mt-2">{error}</p>}
              <button
                onClick={handleLogout}
                className="mt-4 bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md transition duration-300"
              >
                Logout
              </button>
            </div>
          ) : (
            <div>
              <p className="text-gray-700">Please log in to view your profile.</p>
              <Link to="/login" className="hover:underline mt-2 block bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md transition duration-300">
                Login
              </Link>
            </div>
            )}
          {error && loggedOut !== false && <p className="text-red-500 mt-2">{error}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
}

