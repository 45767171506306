import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/customStyle.css';
import './styles/modal.css'
import '@fortawesome/fontawesome-free/css/all.min.css';

// // Font Awesome script
// const script = document.createElement('script');
// script.src = 'https://kit.fontawesome.com/a076d05399.js';
// script.crossOrigin = 'anonymous';
// document.body.appendChild(script);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

