import React, { useState } from 'react';
import axios from 'axios';
import Navbar from "./shared/Navbar";

const ExternalSearch = () => {
  const [state, setState] = useState({
    searchTerm: '',
    searchResults: [],
    loading: false,
    error: ''
  });

  const { searchTerm, searchResults, loading, error } = state;

  const handleSearch = async () => {
    try {
      setState({ ...state, loading: true, error: '' });
      const response = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/amazon-search`,  { params: { keywords: searchTerm } });
      const searchResults = response.data.SearchResult.Items || [];
      setState({ ...state, searchResults, loading: false });
    } catch (error) {
      setState({ ...state, error: 'Error fetching search results. Please try again later.', loading: false });
      console.error('Error fetching search results:', error);
    }
  };

  const handleChange = (e) => {
    setState({ ...state, searchTerm: e.target.value });
  };

  console.log(searchResults);
  return (
    <div className="text-center overflow-hidden h-screen">
      <Navbar />
      <div className="container mx-auto max-w-3xl mt-8">
        <div className="flex items-center border-b-2 border-blue-500 py-2">
          <input
            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
            type="text"
            placeholder="Enter URL to search"
            value={searchTerm}
            onChange={handleChange}
          />
          <button
            className="flex-shrink-0 bg-blue-500 hover:bg-blue-700 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded"
            type="button"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>

        {loading && <p className="mt-4">Loading...</p>}

        {error && <p className="text-red-500 mt-4">{error}</p>}

        {searchResults.length > 0 && (
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Search Results</h2>
            <ul className="list-disc pl-4">
              {searchResults.map((result, index) => (
                <li key={index}>
                  <a href={result.DetailPageURL} target="_blank" rel="noopener noreferrer">{result.Title}</a>
                  <p>{result.Features ? result.Features.join(', ') : ''}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExternalSearch;
