import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link, useLocation} from 'react-router-dom';
import Navbar from "./shared/Navbar";
import AddToCart from "./shared/AddToCart";
import Footer from "./shared/Footer";
import Pagination from "./shared/Pagination";

export default function Funko() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search)
  const genre = query.get('genre')
  const [loading, setLoading] = useState(true);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleProducts, setVisibleProducts] = useState(9);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const productsPerPage = 9;
  const dollarPrice = Number(process.env.REACT_APP_DOLLAR_PRICE);

  useEffect(() => {
    const fetchProducts = async () => {
      const token = localStorage.getItem('token');
      try {
        const url = `${process.env.REACT_APP_FRONT_URL}/api/products?category=funko&genre=${genre || ''}`
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setProducts(response.data);
      } catch (error) {
        setError('Error fetching products: ' + (error.response ? error.response.data.message : error.message));
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [genre]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const showAll = () => {
    setShowMoreLoading(true);
    setTimeout(() => {
      setShowAllProducts(true);
      setShowMoreLoading(false);
    }, 1000);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setShowAllProducts(false);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = showAllProducts ? products : products.slice(indexOfFirstProduct, indexOfLastProduct);

  const formatPrice = (price) => {
    return price.toLocaleString();
  };

  return (
    <div className="text-center h-screen">
      <Navbar />
      {error && <div className="text-red-500">{error}</div>}
      <div className="grid grid-cols-3 h-auto gap-5 p-4 pt-0 items-center shadow-lg sticky top-0 z-5 md:grid-cols-6 lg:grid-cols-12 lg:gap-x-6 gap-y-0" style={{ backgroundImage: 'url(/images/bg-image2.jpg)',backgroundRepeat: 'no-repeat',backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div className="col-start-1 col-span-full pt-10 pb-24 mt-5 mb-20 grid grid-cols-2 gap-2 md:gap-6 sm:grid-cols-2 md:grid-cols-3 xl:col-start-2 xl:col-span-10 xl:grid-cols-5">
          {currentProducts.map((product) => (
            <div key={product.id} className="rounded overflow-hidden shadow-lg m-2 flex flex-col justify-between bg-white">
              <Link to={`/products/${product.id}`} className="flex flex-col items-center">
                {product.images && product.images.length > 0 ? (
                  <img
                    className={`w-full h-36 object-contain cursor-pointer hover:scale-105 transition-transform duration-300 md:mt-6 md:p-4 md:h-64 ${product.category === 'Funko' ? '' : 'scale-120 hover:scale-125'}`}
                    src={`${process.env.REACT_APP_FRONT_URL}${product.images[0]}`}
                    alt={product.name}
                  />
                ) : (
                  <img
                    className="w-full h-36 object-contain md:mt-6 md:p-4 md:h-64"
                    src="/images/default-placeholder.png"  // Fallback image if no images are available
                    alt="No image available"
                  />
                )}
                <div className="px-4 py-2 text-left w-full">
                  <p className="text-gray-500 text-sm mb-1 pt-3 md:text-base">{product.category}</p>
                  <p className="text-blue-500 text-sm mb-4 md:text-base">{product.genre}</p>
                  <div className="text-left font-bold text-xs overflow-hidden text-ellipsis lg:text-base">{product.name}</div>
                  <p className="text-gray-900 text-xs mb-4 pt-3 md:text-base">{formatPrice(product.price * dollarPrice)}<span className="pl-2 text-blue-500">T</span></p>
                </div>
              </Link>
              <div className="px-4 py-2 md:px-14 lg:px-3">
                <AddToCart product={product} />
              </div>
            </div>
          ))}
          {showAllProducts === false ?
            <Pagination
              visibleProducts={visibleProducts}
              productsLength={products.length}
              showMoreLoading={showMoreLoading}
              showAll={showAll}
              currentPage={currentPage}
              onPageChange={handlePageClick}
            />
            : ''
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}