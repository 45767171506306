export default function Validation(values) {
  let errors = {}
  const email_pattern = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})$/
  const password_pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/

  if(values.email === "") {
    errors.email = "Name should not be empty"
  } else if(!email_pattern.test(values.email)) {
    errors.email = "Email didn't match"
  } else {
    errors.email = ""
  }

  if(values.password === "") {
    errors.password = "Password should not be empty"
  } else if(!password_pattern.test(values.password)) {
    errors.password = "Password didn't match"
  } else {
    errors.password = ""
  }
  return errors
}