import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../CartContext";

export default function Navbar() {
  const [searchTerm, setSearchTerm] = useState('')
  const navigate = useNavigate()
  const { cart, setCart } = useCart();
  const userEmail = localStorage.getItem('email')
  const savedCart = localStorage.getItem(`cart_${userEmail}`);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, [userEmail, setCart]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop > lastScrollTop) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  const handleSearch = (e) => {
    e.preventDefault()
    if (searchTerm.trim()) {
      navigate(`/search?search=${searchTerm}`)
    }
    setShowSearch(!showSearch);
    setSearchTerm('')
  }

  const removeSearch = () => {
    setSearchTerm('')
  }
  
  const MagnifierClick = () => {
    setShowSearch(!showSearch);
  }

  return (
    <div className={`grid grid-cols-3 h-auto gap-5 p-4 items-center shadow-lg sticky top-0 z-10 bg-gray-800 md:grid-cols-6 lg:grid-cols-12 lg:gap-6 transition-transform duration-300 ${showNavbar ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="col-start-1 col-span-1 self-center text-left lg:hidden">
        <button
          className="text-gray-300 pt-1 focus:outline-none"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
      <div className="col-start-2 col-span-1 justify-self-center md:col-start-3 md:col-span-2 lg:col-start-2 lg:col-span-1">
        <a href="/" className="text-gray-300 hover:text-white" rel="noopener noreferrer">
          <img src='/images/logo3.jpg' alt="figurezone" className="w-20 rounded-full text-black" />
        </a>
      </div>
      <div className="hidden focus:outline-none group justify-self-center col-start-4 col-span-1 relative cursor-pointer self-center lg:block">
        <Link to="/products/action-figures" className="menu-hover my-2 py-2 text-sm font-medium text-gray-300 hover:text-white lg:mx-4">
          STATUES
        </Link>
        <div className="absolute z-50 mt-1 bg-gray-800 rounded-md shadow-lg hidden group-hover:block md:w-max">
          <Link to="/products/action-figures" className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-300 hover:text-white md:mx-2">All STATUES</Link>
          <Link to="/products/action-figures?genre=superheroes" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Superheroes</Link>
          <Link to="/products/action-figures?genre=music" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Music</Link>
          <Link to="/products/action-figures?genre=gaming" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Gaming</Link>
          <Link to="/products/action-figures?genre=movies-tv" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Movies & TV</Link>
        </div>
      </div>
      <div className="hidden focus:outline-none group col-start-5 col-span-1 relative cursor-pointer self-center lg:block">
        <Link to="/products/funko-pops" className="menu-hover my-2 py-2 text-sm font-medium text-gray-300 hover:text-white lg:mx-4">
          POPS
        </Link>
        <div className="absolute z-50 mt-1 bg-gray-800 rounded-md shadow-lg hidden group-hover:block md:w-max">
          <Link to="/products/funko-pops" className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-300 hover:text-white md:mx-2">All POPS</Link>
          <Link to="/products/funko-pops?genre=superheroes" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Superheroes</Link>
          <Link to="/products/funko-pops?genre=music" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Music</Link>
          <Link to="/products/funko-pops?genre=sport" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Sport</Link>
          <Link to="/products/funko-pops?genre=gaming" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Gaming</Link>
          <Link to="/products/funko-pops?genre=anime-manga" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Anime & Manga</Link>
          <Link to="/products/funko-pops?genre=movies-tv" className="block px-4 py-2 text-sm text-gray-300 hover:text-white">Movies & TV</Link>
        </div>
      </div>
      <div className="hidden focus:outline-none group col-start-6 col-span-1 relative cursor-pointer self-center lg:block">
        <Link to="/products/funko-pops" className="menu-hover my-2 py-2 text-sm font-medium text-gray-300 hover:text-white lg:mx-4">
          SALE
        </Link>
      </div>
      <div className="hidden focus:outline-none group col-start-7 col-span-1 relative cursor-pointer self-center lg:block">
        <Link to="/contact-us" className="menu-hover my-2 py-2 text-sm font-medium text-gray-300 hover:text-white lg:mx-0">
          CONTACT US
        </Link>
      </div>
      <div className={`lg:hidden absolute top-0 left-0 w-full bg-gray-800 mt-24 pt-10 ${menuOpen ? 'block' : 'hidden'}`}>
        <div className="flex flex-col items-center">
          <Link to="/products/action-figures" className="my-2 py-2 text-base font-medium text-gray-300 hover:text-white">STATUES</Link>
          <Link to="/products/funko-pops" className="my-2 py-2 text-base font-medium text-gray-300 hover:text-white">POPS</Link>
          <Link to="/products/funko-pops" className="my-2 py-2 text-base font-medium text-gray-300 hover:text-white">SALE</Link>
          <Link to="/contact-us" className="my-2 py-2 text-base font-medium text-gray-300 hover:text-white">CONTACT US</Link>
          <div className="flex py-4">
            <div className="circle bg-white p-2 mx-2 rounded-full hover:scale-110 transition-transform duration-300">
              <a href="https://www.youtube.com/@figureZonee" className="text-gray-300 hover:text-white" target='_blank' rel="noopener noreferrer">
                <img src='https://funko.com/on/demandware.static/-/Sites-FunkoUS-Library/default/dw503a959d/images/funko/social/social-youtube.svg' alt="Youtube" className="w-6 h-6 text-black" />
              </a>
            </div>
            <div className="circle bg-white p-2 mx-2 rounded-full hover:scale-110 transition-transform duration-300">
              <a href="https://www.instagram.com/funko.zone.ir/" className="text-gray-300 hover:text-white" target='_blank' rel="noopener noreferrer">
                <img src='https://funko.com/on/demandware.static/-/Sites-FunkoUS-Library/default/dw32096bf9/images/funko/social/social-instagram.svg' alt="Instagram" className="w-6 h-6 text-black" />
              </a>
            </div>
            <div className="circle bg-white p-2 mx-2 rounded-full hover:scale-110 transition-transform duration-300">
              <Link to="/profile" className="text-gray-300 hover:text-white">
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCHU5JIkqfD2z1KMc4c1nW4zdArnxBM3cCcQ&s' alt="Profile" className="w-6 h-6 text-black" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-start-3 col-span-1 self-center p-0.5 text-right md:col-start-6 lg:col-start-9 lg:col-span-4 lg:flex lg:float-right lg:space-x-4 xl:justify-self-end xl:col-start-9 xl:col-span-3">
        <button onClick={MagnifierClick} type="submit" className="circle bg-white mr-2 px-2 py-1 rounded-full hover:scale-110 transition-transform duration-300 relative lg:px-2.5 lg:py-2 lg:mr-0">
          <FontAwesomeIcon icon={faSearch} className="self-center text-black text-m lg:text-xl" />
        </button>
        <div className="circle bg-white px-2 py-1 self-center rounded-full hover:scale-110 inline-flex transition-transform duration-300 relative lg:text-base lg:p-2 lg:pt-2.5 lg:pb-1.5">
          <Link to="/cart" className="text-black">
            <FontAwesomeIcon icon={faShoppingCart} className="text-sm lg:text-xl" />
            {cart.length > 0 && (
              <span className="absolute top-0 right-0 items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full lg:px-1.5 lg:py-1">
                  {cart.length}
                </span>
            )}
          </Link>
        </div>
        <div className="hidden circle bg-white p-2 rounded-full hover:scale-110 lg:block transition-transform duration-300">
          <a href="https://www.youtube.com/@figureZonee" className="text-gray-300 hover:text-white" target='_blank' rel="noopener noreferrer">
            <img src='https://funko.com/on/demandware.static/-/Sites-FunkoUS-Library/default/dw503a959d/images/funko/social/social-youtube.svg' alt="Youtube" className="w-6 h-6 text-black" />
          </a>
        </div>
        <div className="hidden circle bg-white p-2 rounded-full hover:scale-110 lg:block transition-transform duration-300">
          <a href="https://www.instagram.com/funko.zone.ir/" className="text-gray-300 hover:text-white" target='_blank' rel="noopener noreferrer">
            <img src='https://funko.com/on/demandware.static/-/Sites-FunkoUS-Library/default/dw32096bf9/images/funko/social/social-instagram.svg' alt="Instagram" className="w-6 h-6 text-black" />
          </a>
        </div>
        <div className="hidden circle bg-white p-2 rounded-full hover:scale-110 lg:block transition-transform duration-300">
          <Link to="/profile" className="text-gray-300 hover:text-white">
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCHU5JIkqfD2z1KMc4c1nW4zdArnxBM3cCcQ&s' alt="Profile" className="w-6 h-6 text-black" />
          </Link>
        </div>
      </div>
      <div className={`col-start-1 col-span-full items-center justify-center mt-4 lg:col-start-3 lg:col-span-8 ${showSearch ? 'block' : 'hidden'}`}>
        <form onSubmit={handleSearch} className="relative w-full">
          <button type="submit" className="absolute left-2 top-0 mt-2 mr-3">
            <FontAwesomeIcon icon={faSearch} className="text-gray-300" />
          </button>
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-8 p-2 rounded text-black w-full"
          />
          <button onClick={removeSearch} type="button" className="absolute right-0 top-0 mt-2 mr-3">
            <FontAwesomeIcon icon={faTimes} className="text-gray-300 text-xl" />
          </button>
        </form>
      </div>
    </div>
  );
}
