import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import SignUpValidation from "../SignUpValidation";

export default function Signup() {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
  })

  const navigate = useNavigate()
  const [errors, setErrors] = useState({})

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors(SignUpValidation(values))
    if (!errors.name && !errors.email && !errors.password) {
      console.log('Signup form values:', values); // Log form values
      try {
        const response = await axios.post(`${process.env.REACT_APP_FRONT_URL}/api/signup`, values);
        if (response.status === 200) {
          console.log("Signup successful");
          navigate('/'); // Navigate to home
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleInput = (e) => {
    setValues(prev => ({...prev, [e.target.name]: [e.target.value]}))
  }

  return (
    <div className="flex flex-col min-h-full pb-32 justify-center align-center bg-slate-400">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Create an account</h2>
      </div>
      <div className="w-full justify-center align-center mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="">
            <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="name"><strong>Name</strong></label>
            <input type="text" onChange={handleInput} name='name' placeholder="Enter Your Name" className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            {errors && errors.name && <span className="text-red-500 text-xs italic">{errors.name}</span>}
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="email"><strong>Email</strong></label>
            <input type="email" onChange={handleInput} name='email' placeholder="Enter Your Email" className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            {errors && errors.email && <span className="text-red-500 text-xs italic">{errors.email}</span>}
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="password"><strong>Password</strong></label>
            <input type="password" onChange={handleInput} id="password" name="password" className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            {errors && errors.password && <span className="text-red-500 text-xs italic">{errors.password}</span>}
          </div>
          <button className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" type="submit">Sign Up</button>
          <p className="flex mt-10 text-center text-sm text-black">Already a member? <span className="ml-2 text-gray-50" ><Link to="/" type="submit"> Log in</Link></span></p>
        </form>
      </div>
    </div>
  );
}