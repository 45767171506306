import React, {useState} from 'react';
import axios from 'axios'
import {Link, useNavigate} from "react-router-dom";
import Validation from '../LoginValidation'

function Login() {
  const [values, setValues] = useState({
    email: '',
    password: ''
  })

  const email = values.email
  const navigate = useNavigate()
  const [errors, setErrors] = useState({})
  const [loginError, setLoginError] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrors(Validation(values))
    if (!errors.email && !errors.password) {
      axios.post(`${process.env.REACT_APP_FRONT_URL}/api/login`, values)
        .then(res => {
          if (res.data.message === 'Login successful') {
            console.log("login successful");
            localStorage.setItem('token', res.data.accessToken); // Store the token
            localStorage.setItem('email', values.email); // Store the user's email
            navigate('/home')
          } else {
            setLoginError(res.data.message);
          }
        })
        .catch(err => {
          console.log(err)
          setLoginError('An error occurred while logging in.');
        })
    }
  }

  const handleInput = (e) => {
    setValues(prev => ({...prev, [e.target.name]: [e.target.value]}))
  }

  return (
    <div className="flex flex-col min-h-full pb-32 justify-center align-center bg-slate-400">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
      </div>
      <div className="w-full justify-center align-center mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="email"><strong>Email Address</strong></label>
            <input type="email" onChange={handleInput} name='email' placeholder="Enter Your Email" className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            {errors && errors.email && <span className="text-red-500 text-xs italic">{errors.email}</span>}
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="password"><strong>Password</strong></label>
            <input type="password" onChange={handleInput} id="password" name="password" className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            {errors && errors.password && <span className="text-red-500 text-xs italic">{errors.password}</span>}
          </div>
          {loginError && <div className="text-red-500 text-xs italic">{loginError}</div>}
          <button className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" type="submit">Log in</button>
          <p className="flex mt-10 text-center text-sm text-black">Not a member? <span className="ml-2 text-gray-50" ><Link to="/signup" type="submit">Create an account</Link></span></p>
        </form>
      </div>
    </div>
  );
}

export default Login;