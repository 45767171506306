import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Navbar from "./shared/Navbar";
import AddToCart from "./shared/AddToCart";
import Modal from 'react-modal'

Modal.setAppElement('#root'); // This prevents screen readers from reading the background content when the modal is open

export default function ProductPage() {
  const { id } = useParams()
  const [product, setProduct] = useState(null)
  const [error, setError] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Keep track of the index of the selected image
  const [selectedImage, setSelectedImage] = useState(null); // State to keep track of the selected image for the modal
  const navigate = useNavigate()
  const dollarPrice = Number(process.env.REACT_APP_DOLLAR_PRICE);

  const adminToken = localStorage.getItem('adminToken');

  useEffect( () => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/products/${id}`)
        setProduct(response.data);
      } catch (error) {
        setError('Error fetching product: ' + (error.response ? error.response.data.message : error.message))
        console.error('Error fetching product:', error);
      }
    }
    fetchProduct()

    if (adminToken) {
      setIsAdmin(true);
    }
  }, [id])

  const handleEdit = () => {
    navigate(`/admin/products/edit/${id}`);
  };

  const handleDelete = async () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this product?');
    if (!isConfirmed) {
      return; // User canceled the delete action
    }

    try {
      await axios.delete(`${process.env.REACT_APP_FRONT_URL}/api/admin/products/${id}`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      alert('Product deleted successfully')
      navigate(-1);
    } catch (error) {
      console.error('Error deleting product:', error);
      alert('Failed to delete product');
    }
  }

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const nextImage = () => {
    if (product.images) {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const prevImage = () => {
    if (product.images) {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
      );
    }
  };
  if (error) {
    return <div className="text-center mt-5 text-red-500">{error}</div>;
  }

  if (!product) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  const formatPrice = (price) => {
    return price.toLocaleString();
  };

  return (
    <div className="text-center">
      <Navbar />
      <div className="max-w-2xl mx-auto mt-8 bg-white shadow-md rounded p-8">
        <div className="flex space-x-4 overflow-x-auto mb-4">
          {product.images && product.images.map((image, index) => (
            <img
              key={index}
              className="w-48 h-48 object-cover cursor-pointer"
              src={`${process.env.REACT_APP_FRONT_URL}${image}`}
              alt={`${product.name} image ${index + 1}`}
              onClick={() => openModal(`${process.env.REACT_APP_FRONT_URL}${image}`)} // Open modal on image click
            />
          ))}
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Image Modal"
          className="flex items-center justify-center"
          style={{
            content: {
              inset: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.8)',
              border: 'none',
              padding: '0px',
              margin: '250px auto',
              maxWidth: '500px',
              position: 'relative',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
          }}
        >
          {product.images && product.images.length > 0 && (
            <div className="relative flex justify-center items-center">
              <button
                onClick={closeModal}
                className="absolute top-0 right-2 bg-gray text-white font-bold rounded-full text-3xl"
              >
                &times;
              </button>
              <button onClick={prevImage} className="absolute left-2 text-white text-lg bg-gray rounded-full">
                &#9664;
              </button>
              <button onClick={nextImage} className="absolute right-2 text-white text-lg bg-gray rounded-full">
                &#9654;
              </button>

              <img
                src={`${process.env.REACT_APP_FRONT_URL}${product.images[selectedImageIndex]}`}
                alt={`Selected ${selectedImageIndex + 1}`}
                className="w-64 h-auto object-contain"
              />
            </div>
          )}
        </Modal>

        <h1 className="text-2xl font-bold mt-4">{product.name}</h1>
        <p className="text-gray-700 mt-2">{product.description}</p>
        <p className="text-gray-900 font-bold mt-4">{formatPrice(product.price * dollarPrice)}</p>
        <p className="text-gray-600 mt-2">Category: {product.category}</p>
        <div className="px-6 py-4">
          <AddToCart product={product} />
        </div>
        {isAdmin && (
          <div className="mt-4">
            <button onClick={handleEdit} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mr-2">Edit</button>
            <button onClick={handleDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Delete</button>
          </div>
        )}
      </div>
    </div>
  );
}
