import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import Navbar from "./shared/Navbar";
import AddToCart from "./shared/AddToCart";

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function SearchResult() {
  const [products, setProducts] = useState([])
  const [error, setError] = useState(null)
  const query = useQuery().get('search')
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/products/search?query=${query}`)
        setProducts(response.data);
      } catch (error) {
        setError('Error fetching search results: ' + (error.response ? error.response.data.message : error.message))
        console.log('Error fetching search results:', error)
      }
    }
    fetchProducts()
  }, [query])

  if (error) {
    return <div className="text-center mt-5 text-red-500">{error}</div>;
  }

  return (
    <div className="text-center">
      <Navbar />
      <h1>Search Results for "{query}"</h1>
      {products.length === 0 ? (
        <p>No products found</p>
      ) : (
        <div className="flex flex-wrap justify-center mt-5">
          {products.map(product => (
            <div key={product.id} className="max-w-sm rounded overflow-hidden shadow-lg m-4 flex flex-col justify-between">
              <Link to={`/products/${product.id}`} className="flex-grow">
                <img className="w-full h-[500px] object-cover hover:scale-105" src={product.imageUrl} alt={product.name} />
                <div className="px-6 py-4 flex flex-col items-center flex-grow">
                  <div className="font-bold text-xl mb-2">{product.name}</div>
                  <p className="text-gray-900 font-bold mb-4">${product.price}</p>
                </div>
              </Link>
              <div className="px-6 py-4">
                <AddToCart product={product} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

