import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "./shared/Navbar";
import AddToCart from "./shared/AddToCart";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from "./shared/Footer";
import Pagination from "./shared/Pagination";
import './../styles/sliderStyles.css'

export default function Home() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleProducts, setVisibleProducts] = useState(9);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [productsPerPage, setProductsPerPage] = useState(10);  // Adjust this later based on screen size
  const dollarPrice = Number(process.env.REACT_APP_DOLLAR_PRICE);

  const youtubeShorts = [
    { url: "https://www.youtube-nocookie.com/embed/Pap_tx8jPNA" },
    { url: "https://www.youtube-nocookie.com/embed/gGoMFQJyYm8" },
    { url: "https://www.youtube-nocookie.com/embed/G58eULyd92Q" },
    { url: "https://www.youtube-nocookie.com/embed/vEIu-pdUWOI" },
    { url: "https://www.youtube-nocookie.com/embed/xALkfX7lBlw" },
    { url: "https://www.youtube-nocookie.com/embed/PzZ95SS6cLk" },
    { url: "https://www.youtube-nocookie.com/embed/mRugP-dqjxE" },
    { url: "https://www.youtube-nocookie.com/embed/ulSoYaua-hA" },
    { url: "https://www.youtube-nocookie.com/embed/dOVuzrexytc" },
  ];

  const updateProductsPerPage = () => {
    if (window.innerWidth > 1280) {
      setProductsPerPage(10);
    } else if (window.innerWidth < 768) {
      setProductsPerPage(10);
    } else if (678 <= window.innerWidth <= 1024){
      setProductsPerPage(9);
    }
  };

  useEffect(() => {
    updateProductsPerPage(); // Set the initial productsPerPage based on the current screen size

    window.addEventListener('resize', updateProductsPerPage); // Listen for window resizing

    return () => window.removeEventListener('resize', updateProductsPerPage); // Clean up event listener
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/products`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setProducts(response.data);
      } catch (error) {
        setError('Error fetching products: ' + (error.response ? error.response.data.message : error.message));
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 4000,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          speed: 1500,
          autoplaySpeed: 3000
        }
      }
    ]
  };

  const youtubeShortsSlide = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    centerMode: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1535,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  };

  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          zIndex: 50,
          width: "25px",
          height: "25px",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          boxShadow: "0 0 5px 2px white"
        }}
        onClick={onClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" focusable="false" style={{ pointerEvents: 'none', display: 'inherit', width: '100%', height: '100%' }}>
          <path d="M14.96 18.96 8 12l6.96-6.96.71.71L9.41 12l6.25 6.25-.7.71z" fill="white"></path>
        </svg>
      </div>
    );
  }

  function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          zIndex: 50,
          width: "25px",
          height: "25px",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          boxShadow: "0 0 5px 2px white"
        }}
        onClick={onClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" focusable="false" style={{ pointerEvents: 'none', display: 'inherit', width: '100%', height: '100%' }}>
          <path d="M9.04 18.96 16 12 9.04 5.04l-.71.71L14.59 12l-6.25 6.25.7.71z" fill="white"></path>
        </svg>
      </div>
    );
  }


  const showAll = () => {
    setShowMoreLoading(true);
    setTimeout(() => {
      setShowAllProducts(true);
      setShowMoreLoading(false);
    }, 1000);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setShowAllProducts(false);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = showAllProducts ? products : products.slice(indexOfFirstProduct, indexOfLastProduct);

  const formatPrice = (price) => {
    return price.toLocaleString();
  };

  const truncateName = (name) => {
    const wordLimit = 15;
    return name.split(' ').length > wordLimit
      ? name.split(' ').slice(0, wordLimit).join(' ') + '...'
      : name;
  };

  return (
    <div className="text-center overflow-hidden">
      <Navbar />
      <div className="grid grid-cols-4 h-full gap-5 p-4 pt-0 items-center shadow-lg sticky top-0 z-5 md:grid-cols-6 lg:grid-cols-12 lg:gap-x-6 gap-y-0" style={{ backgroundImage: 'url(/images/bg-image3.jpg)',backgroundRepeat: 'no-repeat',backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div className="col-start-1 col-span-full pb-2 pt-16 h-full lg:col-start-3 lg:col-span-8 lg:pb-20 lg:pt-32">
          <Slider {...sliderSettings}>
            <div className="px-14">
              <div className="relative w-full h-auto ">
                <img src="/images/Tade-Batman-joker-bust.JPG" className="w-full h-full pb-4 object-contain" alt="Image 1" />
              </div>
            </div>
            <div className="px-14">
              <div className="relative w-full h-auto ">
                <img src="/images/pheonix-joker.JPEG" className="w-full h-full pb-4 object-contain" alt="Image 2" />
              </div>
            </div>
            <div className="px-14">
              <div className="relative w-full h-auto ">
                <img src="/images/Narek-Tadeh.JPEG" className="w-full h-full pb-4 object-contain" alt="Image 3" />
              </div>
            </div>
            <div className="px-14">
              <div className="relative w-full h-auto ">
                <img src="/images/Scorpionvssub-zero.JPEG" className="w-full h-full pb-4 object-contain" alt="Image 4" />
              </div>
            </div>
          </Slider>
        </div>
        <div className="col-start-1 col-span-full pt-4 pb-10 lg:pb-24 lg:col-start-2 lg:col-span-10">
          <h1 className="text-3xl py-14 font-bold text-white">Discover Our Videos on Figurezone</h1>
          <Slider {...youtubeShortsSlide}>
            {youtubeShorts.map((video, index) => (
              <div key={index} className="px-2 youtube-short-slide">
                <div className="relative w-full h-80 flex flex-col justify-center items-center">
                  <iframe
                    width="240"
                    height="426"
                    src={video.url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="object-contain cursor-pointer
                     w-[140px] h-[220px]
                     sm:w-[220px] sm:h-[260px]
                     md:w-[240px] md:h-[280px]
                     lg:w-[240px] lg:h-[370px]
                     xl:w-[240px] xl:h-[426px]
                     2xl:w-[260px] 2xl:h-[480px]"
                    title={`YouTube Short ${index + 1}`}
                  ></iframe>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="col-start-1 col-span-full pt-10 flex-wrap justify-self-center">
          <h1 className="text-3xl py-14 font-bold text-white">OUR PRODUCTS</h1>
        </div>
          <div className="col-start-1 col-span-full pt-10 pb-24 mt-5 mb-20 grid grid-cols-2 gap-2 md:gap-6 sm:grid-cols-2 md:grid-cols-3 xl:col-start-2 xl:col-span-10 xl:grid-cols-5">
            {currentProducts.map((product) => (
              <div key={product.id} className="rounded overflow-hidden shadow-lg m-2 flex flex-col justify-between bg-white">
                <Link to={`/products/${product.id}`} className="flex flex-col items-center">
                  {product.images && product.images.length > 0 ? (
                    <img
                      className={`w-full h-36 object-contain cursor-pointer hover:scale-105 transition-transform duration-300 md:mt-6 md:p-4 md:h-64 ${product.category === 'Funko' ? '' : 'scale-120 hover:scale-125'}`}
                      src={`${process.env.REACT_APP_FRONT_URL}${product.images[0]}`}
                      alt={product.name}
                    />
                  ) : (
                    <img
                      className="w-full h-36 object-contain md:mt-6 md:p-4 md:h-64"
                      src="/images/default-placeholder.png"
                      alt="No image available"
                    />
                  )}
                  <div className="px-4 py-2 text-left w-full">
                    <p className="text-gray-500 text-sm mb-1 pt-3 md:text-base">{product.category}</p>
                    <p className="text-blue-500 text-sm mb-4 md:text-base">{product.genre}</p>
                    <div className="text-left font-bold text-xs overflow-hidden text-ellipsis whitespace-nowrap lg:text-base">{truncateName(product.name)}</div>
                    <p className="text-gray-900 text-xs mb-4 pt-3 md:text-base">{formatPrice(product.price * dollarPrice)}<span className="pl-2 text-blue-500">T</span></p>
                  </div>
                </Link>
              <div className="px-4 py-2 md:px-14 lg:px-3">
                <AddToCart product={product} />
              </div>
            </div>
          ))}
          {showAllProducts === false ?
            <Pagination
              visibleProducts={visibleProducts}
              productsLength={products.length}
              showMoreLoading={showMoreLoading}
              showAll={showAll}
              currentPage={currentPage}
              onPageChange={handlePageClick}
            />
            : ''
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}
