import React from 'react';
import {useCart} from "../CartContext";
import {useNavigate} from "react-router-dom";

export default function AddToCart({ product }) {
  const { addToCart, cart } = useCart()
  const navigate = useNavigate();
  const isInCart = cart.some(item => item.id === product.id);

  const handleAddToCart = () => {
    if (!isInCart) {
      addToCart(product);
    }
  };

  return (
    <button
      onClick={handleAddToCart}
      className={`${isInCart ? 'bg-black cursor-not-allowed text-white' : 'text-black bg-gray-100 hover:outline hover:outline-1 hover:outline-gray-800 cursor-pointer'} text-xs font-proxima xl:text-sm py-2 px-4 rounded w-full`}
      disabled={isInCart}
      style={{ fontWeight: 700 }}
    >
      {isInCart ? 'IN CART' : 'ADD TO CART'}
    </button>
  );
}
