import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "./shared/Navbar";

export default function EditProduct() {
  const { id } = useParams();
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    genre: ''
  });
  const [genres, setGenres] = useState(['Superheroes', 'Music', 'Sport', 'Gaming', 'Anime-Manga', 'Movies-TV']);
  const [selectedImages, setSelectedImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]); // To hold current images
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FRONT_URL}/api/products/${id}`);
        setProduct(response.data);
        setExistingImages(response.data.images); // Set existing images
      } catch (error) {
        setError('Error fetching product: ' + (error.response ? error.response.data.message : error.message));
        console.error('Error fetching product:', error);
      }
    };
    fetchProduct();
  }, [id]);

  const handleInputChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setSelectedImages(e.target.files);
  };

  const handleRemoveImage = (imageIndex) => {
    const updatedImages = existingImages.filter((_, index) => index !== imageIndex);
    setExistingImages(updatedImages); // Update the state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('adminToken');

    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('category', product.category);
    formData.append('genre', product.genre);

    // Append new images if selected
    for (let i = 0; i < selectedImages.length; i++) {
      formData.append('images', selectedImages[i]);
    }

    formData.append('existingImages', JSON.stringify(existingImages)); // Send updated existing images list

    try {
      await axios.put(`${process.env.REACT_APP_FRONT_URL}/api/admin/products/edit/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Product updated successfully');
      navigate(`/products/${id}`);
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Failed to update product');
    }
  };

  if (error) {
    return <div className="text-center mt-5 text-red-500">{error}</div>;
  }

  return (
    <div className="overflow-hidden">
      <Navbar />
      <div className="max-w-md mx-auto bg-white shadow-md rounded p-8 mt-8">
        <h2 className="text-xl font-semibold mb-4">Edit Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Name</label>
            <input type="text" name="name" value={product.name} onChange={handleInputChange} className="block w-full border rounded px-4 py-2" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Description</label>
            <textarea name="description" value={product.description} onChange={handleInputChange} className="block w-full border rounded px-4 py-2"></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Price</label>
            <input type="number" name="price" value={product.price} onChange={handleInputChange} className="block w-full border rounded px-4 py-2" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Category</label>
            <input type="text" name="category" value={product.category} onChange={handleInputChange} className="block w-full border rounded px-4 py-2" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Genre</label>
            <select name="genre" value={product.genre} onChange={handleInputChange} className="block w-full border rounded px-4 py-2">
              <option value="">Select Genre</option>
              {genres.map(genre => (
                <option key={genre} value={genre}>{genre}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Upload New Images</label>
            <input type="file" name="images" onChange={handleImageChange} multiple className="block w-full border rounded px-4 py-2" />
            <h4 className="font-bold">Existing Images</h4>
            <div className="flex flex-wrap gap-4">
              {existingImages.map((image, index) => (
                <div key={index} className="relative">
                  <img src={`${process.env.REACT_APP_FRONT_URL}${image}`} alt="Product" className="w-20 h-20 object-cover" />
                  <button
                    type="button"
                    className="absolute top-0 right-0 bg-red-500 text-white text-sm px-1 rounded"
                    onClick={() => handleRemoveImage(index)}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
          </div>

          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Update Product</button>
        </form>
      </div>
    </div>
  );
}
