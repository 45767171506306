import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import Navbar from "./shared/Navbar";
import axios from "axios";
import Footer from "./shared/Footer";

export default function ContactUs() {
  const { register, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm({
    defaultValues: {
      images: []
    }
  });

  const [uploadCount, setUploadCount] = useState(0);
  const [images, setImages] = useState([]);

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('email', data.email);
      formData.append('phone', data.phone);
      formData.append('subject', data.subject);
      formData.append('comments', data.comments);

      // Append up to 5 images
      for (let i = 0; i < data.images.length; i++) {
        formData.append('images', data.images[i]);
      }

      const response = await axios.post(`${process.env.REACT_APP_FRONT_URL}/api/sendMail`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Message sent successfully!');
      console.log(response.data);
      reset({
        name: '',
        email: '',
        phone: '',
        subject: '',
        comments: '',
        images: []
      });
      setUploadCount(0); // Reset upload count
      setImages([]); // Clear images list
    } catch (error) {
      alert('Failed to send message. Please try again later.');
      console.error(error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    // Limit to 5 images
    const selectedFiles = files.slice(0, 5 - images.length);
    setValue('images', [...images, ...selectedFiles]);
    setUploadCount(selectedFiles.length); // Update upload count
    setImages(prevState => [...prevState, ...selectedFiles]); // Update images list
  };

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setValue('images', newImages); // Update form value
    setImages(newImages); // Update images list
    setUploadCount(newImages.length); // Update upload count
  };

  const triggerFileInput = () => {
    document.getElementById('images').click();
  };

  return (
    <div className="flex flex-col min-h-screen" >
      <Navbar />
      <div className="flex-grow flex items-center justify-center p-4" style={{ backgroundImage: 'url(/images/bg-image1.jpg)',backgroundRepeat: 'no-repeat',backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div className="w-full max-w-lg py-20">
          <h2 className="text-2xl text-white font-bold text-center mb-4">Contact Us</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-white text-sm font-medium text-left">Name *</label>
              <input
                id="name"
                name="name"
                type="text"
                {...register('name', { required: 'Name is required' })}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.name && <p className="text-red-500 text-sm text-left">{errors.name.message}</p>}
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-white text-left">Email *</label>
              <input
                id="email"
                name="email"
                type="email"
                {...register('email', { required: 'Email is required' })}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.email && <p className="text-red-500 text-sm text-left">{errors.email.message}</p>}
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-white text-left">Phone Number *</label>
              <input
                id="phone"
                name="phone"
                type="tel"
                {...register('phone', { required: 'Phone number is required' })}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.phone && <p className="text-red-500 text-sm text-left">{errors.phone.message}</p>}
            </div>
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-white text-left">Subject *</label>
              <input
                id="subject"
                name="subject"
                type="text"
                {...register('subject', { required: 'Subject is required' })}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.subject && <p className="text-red-500 text-sm text-left">{errors.subject.message}</p>}
            </div>
            <div>
              <label htmlFor="comments" className="block text-sm font-medium text-white text-left">Comments *</label>
              <textarea
                id="comments"
                name="comments"
                {...register('comments', { required: 'Comments are required' })}
                className="mt-1 block h-[150px] w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              ></textarea>
              {errors.comments && <p className="text-red-500 text-sm text-left">{errors.comments.message}</p>}
            </div>
            <div>
              <label className="block text-sm font-medium text-white text-left">Attachments</label>
              <div
                className="mt-1 flex items-center justify-center p-4 border border-gray-300 rounded-md shadow-sm"
                onClick={images.length < 5 ? triggerFileInput : null}
                style={{ cursor: images.length < 5 ? 'pointer' : 'default' }}
              >
                <span className="text-white">📎 Add up to 5 files</span>
              </div>
              <label htmlFor="images" className="block text-sm font-medium text-white text-left">Upload Images (up to 5)</label>
              <input
                id="images"
                name="images"
                type="file"
                onChange={handleFileChange}
                accept="image/jpeg, image/png, image/jpg"
                className="hidden mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                multiple
              />
            </div>

            {images.length > 0 && (
              <div className="mt-2 space-y-2">
                {images.map((image, index) => (
                  <div key={index} className="flex items-center justify-between bg-white p-2 border rounded-md shadow-sm">
                    <span className="text-sm text-white">{image.name}</span>
                    <button
                      type="button"
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                      onClick={() => handleRemoveImage(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className="text-center">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
