// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* customStyles.css */

/* Custom CSS for the arrows */
.slick-prev, .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.slick-prev:before, .slick-next:before {
    font-size: 24px;
    color: white;
    display: none;
}

/* To prevent horizontal overflow */
body, html {
    overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/customStyle.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB,8BAA8B;AAC9B;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,wBAAwB;IACxB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;AACjB;;AAEA,mCAAmC;AACnC;IACI,kBAAkB;AACtB","sourcesContent":["/* customStyles.css */\n\n/* Custom CSS for the arrows */\n.slick-prev, .slick-next {\n    z-index: 1;\n    width: 40px;\n    height: 40px;\n    background-color: rgba(0, 0, 0, 0.5);\n    border-radius: 50%;\n    display: flex !important;\n    justify-content: center;\n    align-items: center;\n}\n\n.slick-prev:before, .slick-next:before {\n    font-size: 24px;\n    color: white;\n    display: none;\n}\n\n/* To prevent horizontal overflow */\nbody, html {\n    overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
