import React from 'react';
import {Link} from "react-router-dom";

export default function Footer() {
  return (
    <div className="grid grid-cols-2 h-auto gap-5 bg-gray-800 pt-10 pb-16 md:grid-cols-7 lg:grid-cols-12 lg:gap-6">
      <div className="col-start-1 col-span-full pt-8 text-white justify-self-center self-center lg:pt-0 lg:col-start-2 lg:col-span-2 lg:justify-self-start">
        <div className="col-start-2 col-span-1 self-center md:col-start-3 md:col-span-2 lg:col-start-2 lg:col-span-1">
          <a href="/" className="text-gray-300 hover:text-white" rel="noopener noreferrer">
            <img src='/images/logo3.jpg' alt="figurezone" className="w-32 rounded-full text-black lg:w-48" />
          </a>
        </div>
      </div>
      <div className="sticky w-full top-0 z-10 col-span-full text-center self-center pb-8 pt-10 md:pt-8 justify-self-start md:text-left md:col-start-2 md:col-span-2 md:pb-16 lg:col-start-5 lg:pt-16">
        <div className="text-xl font-bold text-white mb-8">
          About Us
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/partners'>
            Our partners
          </Link>
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/gallery'>
            Our Gallery
          </Link>
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/policy'>
            Policies
          </Link>
        </div>
      </div>
      <div className="sticky w-full top-0 z-10 col-span-full text-center self-center pb-8 md:pt-8 justify-self-start md:text-left md:col-start-4 md:col-span-2 md:pb-16 lg:col-start-7 lg:pt-16">
        <div className="text-xl font-bold text-white mb-8">
          Our Networks
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/partners'>
           Youtube
          </Link>
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/gallery'>
            Instagram
          </Link>
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/policy'>
            TikTok
          </Link>
        </div>
      </div>
      <div className="sticky w-full top-0 z-10 col-span-full text-center self-center pb-8 md:pt-8 justify-self-start md:text-left md:col-start-6 md:col-span-2 md:pb-16 lg:col-start-9 lg:pt-16">
        <div className="text-xl font-bold text-white mb-8">
          Customer Support
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/partners'>
            Contact Us
          </Link>
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/gallery'>
            My Account
          </Link>
        </div>
        <div className="text-sm opacity-50 cursor-pointer text-white my-4">
          <Link to='/policy'>
            My Orders
          </Link>
        </div>
      </div>
    </div>
  );
}